$quill-light: #454F5B;
$border-radius: 0.25rem !default;
$theme-colors: (
// default colors mapping
  'primary': #096A9A,
  'dark': #22303E,
  'danger': #D72D0C,
  'success': #15A86B,
  'quill': #212B36,
  'quill-lighter': #637381,
  'quill-lightest': #7F8FA4,
  'sky-lighter': #F9FAFB,
  'sky': #dfe3e8
);

$body-color: $quill-light !default;

$font-size-base: 0.875rem !default; // Assumes the browser default, typically `14px`

$h1-font-size:  $font-size-base * 3 !default; //42px
$h2-font-size:  $font-size-base * 2 !default; //28px
$h3-font-size:  $font-size-base * 1.86 !default; //26px
$h4-font-size:  $font-size-base * 1.43 !default; //20px
$h5-font-size:  $font-size-base * 1.15 !default; //16px
$h6-font-size:  $font-size-base !default; //14px

@import '~bootstrap/scss/bootstrap.scss';