.border-bottom-4 {
    border-bottom: 4px solid #096A9A;
}

.border-left-4 {
    border-left: 4px solid #096A9A;
}

.c-pointer {
    cursor: pointer;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7F8FA4
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7F8FA4;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #7F8FA4;
}

.btn:focus,
a:focus,
input:focus,
textarea:focus {
    outline: none;
}

.border-radius-15 {
    border-radius: 15px;
}

.main-body {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.content-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 60px;
    margin-top: 62px;
    margin-left: 300px;
    border-top-right-radius: 8px;
}

.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    line-height: 60px;
}

.page-not-found {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.default-outer-space {
    left: 25px;
    right: 25px;
}

.customSideNavbar {
    left: 25px !important;
    border-top-left-radius: 4px;
    width: 300px;
    /* transition: 0.3s !important; */
}

.customSideNavbar .navbar .navbar-nav .nav-link {
    color: #637381;
    padding: 16px 24px;
    font-size: 1rem;
}

.customSideNavbar .navbar .navbar-nav .nav-link.active {
    color: #096A9A;
    border-left: 4px solid #096A9A;
}

.slim-sidenavbar {
    transform: translateX(-100%) translateX(60px);
    width: 60px;
}

.sidenavbar-collapse {
    margin-left: 60px;
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
}

.user-header-dropdown.dropdown .dropdown-toggle {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    color: white;
    border: 1px solid white;
    background-color: #6F83C1;
    font-size: 12px;
    padding: 6px 8px;
    text-transform: uppercase;
}

.user-header-dropdown.dropdown .dropdown-toggle::after {
    margin-left: 15px;
}

.user-header-dropdown.dropdown .dropdown-menu {
    top: 38px;
    left: auto;
    right: 0;
}

.name-circle {
    height: 30px;
    width: 30px;
    border: 1px solid white;
    background-color: #6F83C1;
    font-size: 10px;
    padding: 7px 8px;
    margin-right: 10px;
    vertical-align: super;
}

/* Account Details START */
.account-summary-data {
    width: 35%;
}

.account-summary-data tbody tr td {
    padding: 2px;
}

.account-summary-data tbody tr td:nth-child(1) {
    color: #637381;
}

.clientid-table-td {
    width: 100px;
    display: inline-block;
}

.account-summary-tab.nav-tabs .nav-link {
    border: 0;
    color: #7F8FA4;
    padding-left: 0;
    padding-right: 0;
}

.account-summary-tab.nav-tabs .nav-link:not(:first-child) {
    margin-left: 25px;
}

.account-summary-tab.nav-tabs .nav-link.active {
    border-bottom: 2px solid #212B36;
    color: #212B36;
}

/* Account Details END */

.toast-bar {
    min-width: 350px;
    margin-left: -125px;
    background-color: #e48f8f;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 100px;
    font-size: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1590px) {
    .monitor-client-search-block {
        max-width: 75%;
        flex: 0 0 75%;
    }
}

.btn.opacity-1{
    opacity: 1;
}