.prohibited-softwares-list-header {
    width: 500px;
}

.prohibited-softwares-list-section {
    width: 450px;
    min-height: 60px;
    max-height: 250px;
    overflow-y: auto;
}

.new-software-win-input-section {
    width: 550px;
}

.new-software-mac-input {
    width: 200px;
}