.settings-tile {
    width: 150px;
    height: 150px;
    cursor: pointer;
}
.branding{
    width: 30%;
}
.toggle-switch{
    width: 60px;
    height: 30px;
    background: #ccc;
    border-radius: 5px;
    cursor: pointer;
    transform: background-color 0.3s;
}
.toggle-switch.enabled{
    background-color: #2C6EF2;
}
.toggle-switch.disabled{
    background-color: #ccc;
}
.toggle-knob{
    width: 28px;
    height: 28px;
    border: 1px solid #BED3E4;
    border-radius: 4px;
    top: 1px;
    left: 1px;
    transition: transform 0.3s;
}
.toggle-switch.enabled .toggle-knob{
    border: 1px solid #2C6EF2;
    transform: translateX(30px);
}